import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { BalanceService } from '../../../services/balance/balance.service';
import { PendingTransactionsService } from '../../../services/pending-transactions/pending-transactions.service';

export interface paymentSuccessData {
  isSuccess: boolean;
  maskedAccountNumber: string;
  transactionId: string;
  webhookResponse: string;
  approvalCode: string;
  messageType: string;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentServiceService {
  // 1: Payment Selection, first page of the flow
  // 2: Payment Information, second page of the flow
  // 3: Payment Confirmation, third and final page of the flow
  paymentLocation: number = 1;
  paymentAmount!: string | undefined;
  paymentSuccessData!: undefined | paymentSuccessData;

  balanceService = inject(BalanceService);
  pendingTransactionsService = inject(PendingTransactionsService);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paymentSuccessSubject = new BehaviorSubject<paymentSuccessData | undefined>(
    undefined
  );
  paymentSuccessResponse$ = this.paymentSuccessSubject.asObservable();

  constructor() {}

  nextPage() {
    this.paymentLocation++;
  }

  previousPage() {
    this.paymentLocation--;
  }

  resetPaymentFlow() {
    this.fetchData();
    this.paymentAmount = undefined;
    this.paymentLocation = 1;
    this.paymentSuccessData = undefined;
    this.paymentSuccessSubject.next(undefined);
  }

  fetchData() {
    this.balanceService.complete = false;
    this.pendingTransactionsService.complete = false;
    this.balanceService.fetchBalance();
    this.pendingTransactionsService.fetchPendingTransactions();
  }
}
